import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { deepmerge } from '@mui/utils';
import getPalette from '../assets/palettes';

const getTheme = (mode: PaletteMode) => {
  const palette = getPalette(mode);
  const typography = {
    typography: {
      fontFamily: ['"Plus Jakarta Sans"', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  };
  const theme = createTheme(deepmerge(palette, typography));

  return theme;
};

export default getTheme;
