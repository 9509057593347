import { FormControl, MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import MUISelect from '@mui/material/Select';
import { validateAddressField } from '../../../core/helpers/formHelpers';
import styles from '../../../assets/sass/abstracts/colors.module.scss';
import { IState } from 'country-state-city';

type StateDropDownProps = {
  states: IState[];
  type: string;
  isRequired: boolean;
  handleStateChange?: (state: string) => void;
};

const StateDropDown = ({ states, type, isRequired, handleStateChange }: StateDropDownProps) => {
  const methods = useFormContext();

  return (
    <FormControl sx={{ width: '100%' }} {...methods.register(`${type}.state`, { required: isRequired })}>
      <Controller
        name={`${type}.state`}
        defaultValue={''}
        control={methods.control}
        render={({ field }) => (
          <div className='field-wrapper'>
            <label style={{ display: 'flex' }}>
              State
              {isRequired && <div className='requiredAsterisk'>*</div>}
            </label>
            <MUISelect
              onChange={(e) => {
                field.onChange(e.target.value);
                if (handleStateChange) {
                  handleStateChange(methods.getValues(`${type}.country`) === 'US' ? e?.target?.value || '' : '');
                }
              }}
              displayEmpty={false}
              className='text-input'
              sx={{ color: field.value !== '' ? '#666' : styles.placeholder_text }}
              id='state'
              value={field.value}
              error={validateAddressField(methods.formState.errors, type, 'state')}
            >
              {states.map((state, i) => {
                return (
                  <MenuItem key={i} value={state.isoCode}>
                    {state.name}
                  </MenuItem>
                );
              })}
            </MUISelect>
          </div>
        )}
      />
    </FormControl>
  );
};

export default StateDropDown;
