import { Card } from '../../../core/interfaces';
import { ISaasConfig } from '../../../core/interfaces/tokenizer.interface';
import { Dispatch, SetStateAction } from 'react';

export interface TokenExProps {
  tokenConfig: ISaasConfig;
  handleSubmit: (value: Card, exp: string | null) => object | void;
  handleError: () => void;
  tokenizerRef: {
    current: {
      validate: () => void;
    };
  };
  validCard: (value: string | null) => boolean | void;
  iqProToken: string | undefined;
  overriddenStyling?: object | undefined;
}

export interface ACHTokenizerProps {
  handleSubmit: (value: string) => object | void;
  handleError: () => void;
  tokenizerRef: {
    current: {
      validate: () => void;
    };
  };
  iqProToken: string | undefined;
  SECCode: number;
  setMaskedPayment?: Dispatch<SetStateAction<string>>;
}

export interface AnyTokenizerProps {
  load: () => void;
  on: (value: string, handler: (data: TokenizerData) => void) => void;
  validate: () => void;
  tokenize: () => void;
  remove: () => void;
}

export interface TokenizerData {
  isCvvValid: boolean;
  isValid: boolean;
  firstSix: string;
}

export const stringFormat = (str: string, args: string[]) => {
  const regexExp = /{([a-zA-Z]+)}/g;
  let count = 0;
  return str.replace(regexExp, function () {
    count++;
    return args[count - 1] || '';
  });
};

export const CARD_FORM_VALIDATION = {
  card: true,
  exp: true,
  cvv: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const correctInput = (event: any) => {
  const target = event.target,
    value = target.value,
    key = event.key,
    monthMin = '01',
    monthMax = '12',
    delimiter = '/';
  let typeKey;
  if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
    if (/(\d)/.test(key)) {
      typeKey = 'number';
    } else {
      typeKey = 'specSymbol';
    }

    if (value.length === 0 && key > 1) {
      // 2 -> 02/
      target.value = '0' + key + delimiter;
      event.preventDefault();
    }

    if (value.length === 1 && value[0] !== '0' && key > 2) {
      // 16 -> 12/
      target.value = monthMax + delimiter;
      event.preventDefault();
      return;
    }

    if (value.length === 1 && typeKey !== 'specSymbol') {
      //12 backspace -> 1
      target.value = target.value + key + delimiter;
      event.preventDefault();
    }

    if (value.length === 2 && typeKey !== 'specSymbol') {
      // 2 -> 02/
      target.value = target.value + delimiter + key;
      event.preventDefault();
      return;
    }

    if (value === '0' && key === '0') {
      // 00 -> 01
      target.value = monthMin + delimiter;
      event.preventDefault();
      return;
    }
  } else {
    event.preventDefault();
    return;
  }
};
